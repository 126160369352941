<template>
	<div class="myAddressContent" :class="themeClass" v-loading="pageLoading">
		<div class="rightContent">
			<el-row>
				<el-col :span="12" class="titleTop">
					<div class="titleBox font-size18 font-weight700">我的地址</div>
				</el-col>
				<el-col :span="12" class="titleTop font-size14 margin-t-5">
					<div v-for="(item,index) of addressTypeList" :key="item.fAddressTypeID">
						<!-- <div v-if="item.fAddressTypeID != 3"> -->
						<div class="comBtn bg-theme font-color-fff bg-theme text-center radius2 margin-l-auto margin-t-5" @click="newAddressbtn(item.fAddressTypeID)"
						 v-if="tabindex==item.fAddressTypeID">
							新增{{item.fAddressType}}
						</div>
						<!-- </div> -->
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<div class="tabBox">
						<span v-for="(item,index) of addressTypeList" :key="item.fAddressTypeID" class="pointer text-center font-size13 font-color-000"
						 :class="[tabindex==item.fAddressTypeID?'navbtn':'']" @click="switchtabar(item.fAddressTypeID)">
							{{item.fAddressType}}
						</span>
					</div>
				</el-col>
			</el-row>
			<div>
				<div class="bgc-pink flex-row">
					<i class="iconfont flex-row icon-tishi5 font-size18"></i>
					<div class="flex-row attestat_box font-size13" v-if="tabindex == 1">
						提示，收货地址是您购买商品到达的地址。
					</div>
					<div class="flex-row attestat_box font-size13" v-if="tabindex == 2">
						提示：收件地址是您购买【金币商城】商品到达的地址。
					</div>
				</div>
				<div class="tabCont">
					<div class="deletrow" v-for="(item,index) of proaddListOne" :data-index="index" :key="index" v-if="item.fIsDefault != 0">
						<div class="padding-10 flex-row">
							<div class="ordername flex-row-space-between-center">
								<div class="flex-row-align-center">
									<div class="pronamespan hide-text margin-r-10" :title="item.fAddressTypeID == '2' || item.fAddressTypeID == '3' ? item.fLinkman : item.fProjectName">{{ item.fAddressTypeID == '2' || item.fAddressTypeID == '3' ? item.fLinkman : item.fProjectName }}</div>
									<em v-if="item.fAddressTypeID == '1' && item.fIsDefault == 1">默认</em>
									<em v-if="item.fAddressTypeID == '2' && item.fIsDefault == 1">默认</em>
								</div>
								<div class="persontel flex-row">
									<div v-if="item.fAddressTypeID == '1'" style="width: 50px;" class="hide-text" :title="item.fLinkman">{{item.fLinkman}}</div>({{item.fLinkPhone|formatPhone}})
								</div>
							</div>
						</div>
						<div class="ordertext twoline-show">
							{{item.areaList}}
						</div>
						<div class="orderstate padding-lr-10">
							<span class="margin-r-15 font-color-333 pointer" v-if="item.fIsDefault == 0 && item.fAddressTypeID != 3" @click="setDefaultAddress(item)">设为默认</span>
							<!-- <span class="shareaddress pointer" v-if="item.fAddressTypeID != 3" @click="sharebtn">分享地址</span> -->
							<span class="iconfont deletIconBtn font-size18 font-color-000 pointer" v-if="item.fAddressTypeID != 3" @click="deleteAddress(item)">&#xe744;</span>
							<span class="iconfont editIconBtn font-size16 font-color-000 pointer" @click="editAddress(item)">&#xe62b;</span>
						</div>
					</div>


					<div class="deletrow" v-for="(item,index) of proaddListOne" :data-index="index" :key="index" v-if="item.fIsDefault == 0 && item.fAddressTypeID != 3">
						<div class="padding-10 flex-row">
							<div class="ordername flex-row-space-between-center">
								<div class="flex-row-align-center">
									<div class="pronamespan hide-text margin-r-10" :title="item.fAddressTypeID == '2' || item.fAddressTypeID == '3' ? item.fLinkman : item.fProjectName">{{ item.fAddressTypeID == '2' || item.fAddressTypeID == '3' ? item.fLinkman : item.fProjectName }}</div>
									<em v-if="item.fAddressTypeID == '1' && item.fIsDefault == 1">默认</em>
									<em v-if="item.fAddressTypeID == '2' && item.fIsDefault == 1">默认</em>
								</div>
								<div class="persontel flex-row">
									<div v-if="item.fAddressTypeID == '1'" style="width: 50px;" class="hide-text" :title="item.fLinkman">{{item.fLinkman}}</div>({{item.fLinkPhone|formatPhone}})
								</div>
							</div>
						</div>
						<div class="ordertext twoline-show">
							{{item.areaList}}
						</div>
						<div class="orderstate padding-lr-10">
							<span class="margin-r-15 font-color-333 pointer" v-if="item.fIsDefault == 0 && item.fAddressTypeID != 3" @click="setDefaultAddress(item)">设为默认</span>
							<!-- <span class="shareaddress pointer" v-if="item.fAddressTypeID != 3" @click="sharebtn">分享地址</span> -->
							<span class="iconfont deletIconBtn font-size18 font-color-000 pointer" v-if="item.fAddressTypeID != 3" @click="deleteAddress(item)">&#xe744;</span>
							<span class="iconfont editIconBtn font-size16 font-color-000 pointer" @click="editAddress(item)">&#xe62b;</span>
						</div>
					</div>

				</div>
			</div>

			<div class="flex-row-center-center" v-if="proaddListOne.length == 0">
				<svg-icon icon-class="NoAddress" />
			</div>
		</div>

		<div :class="themeClass" class="text-center">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="current"
			 :page-size="pagesize" layout="prev, pager, next, jumper" :total="total" prev-text="上一页" next-text="下一页"
			 :pager-count="5">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex';
	import selectArea from '@/components/selectArea/selectArea.vue'
	import pagination from '@/components/pagination/pagination.vue'
	export default {
		data() {
			return {
				pageLoading: false,
				tabindex: 1,
				valueSwitch: false,
				total: 10, //总数据
				currentPage: 1, //当前页
				pagesize: 8, //每页显示10条数据
				area: [],
				addressTypeList: [

				],
				proaddListOne: [
					/* {
						fProjectName: "大阪第一工程",
						fIsDefault: "1",
						fLinkman: "然天亮",
						fLinkPhone: "（234****564）",
						area:fGovernmenAreaID,
						fAddress: "天山大版城第11工地大版城第11工地地大版城第11工地",
						type: "1",
						id: "1",
						sharadd: "分享地址",
						disabled: false,
						checked: false,
					}, */

				],
			}
		},
		computed: {
			current: {
				get() {
					return this.currentPage
				},
				set() {}
			},
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		components: {
			pagination,
			selectArea
		},
		mounted() {
			this.getProjectList();
			this.getAddressType();
		},
		methods: {
			//查询地址类型
			getAddressType() {
				this.ApiRequestPost('/api/mall/ebbase/address-type/get-list', {}).then(
					result => {
						result.obj.items.forEach((item, index) => {
							if (item.fAddressTypeID == 3) {
								result.obj.items.splice(index, 1);
							}
						})
						this.addressTypeList = result.obj.items;
					},
					rej => {}
				);
			},
			//获取地址列表
			getProjectList() {
				this.pageLoading = true;
				this.ApiRequestPost('api/mall/eb-customer/address/get-list-by-current-user-address', {
					addressTypeId: this.tabindex, //地址类型参数
					maxResultCount: this.pagesize, //每页显示10条
					skipCount: (this.currentPage - 1) * this.pagesize, //当前页显示数据（跳过多少条数据）
					filter: "",
					sorting: ""
				}).then(
					result => {
						this.pageLoading = false;
						this.total = result.obj.totalCount;
						this.proaddListOne = result.obj.items
						result.obj.items.forEach((item, index) => {
							item.areaList = item.fFullPathAddress.substr(1).split("\\").join('');
						});

					},
					rej => {
						this.pageLoading = false;
					}
				);
			},
			// 设为默认
			setDefaultAddress(item) {
				console.log(item.fAddressID, "item");
				this.ApiRequestPost('api/mall/eb-customer/address/set-default-address', {
						id: item.fAddressID
					})
					.then(
						res => {
							console.log(res, "res");
							//this.$message({message: '设为默认成功',type: 'success'});
							this.getProjectList();
						},
						error => {}
					);
			},
			//删除地址
			deleteAddress(item) {
				this.$confirm('要删除该地址, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.ApiRequestPost('api/mall/eb-customer/address/delete', {
						"fAddressID": item.fAddressID.toString(),
						"frv": item.frv,
						"rowStatus": 8
					}).then(
						result => {
							this.getProjectList();
						},
						rej => {}
					);
					//this.$message({type: 'success',message: '删除成功!'});
				}).catch(() => {
					/* this.$message({
						type: 'info',
						message: '已取消删除'
					}); */
				});

			},
			switchtabar(index) {
				this.tabindex = index;
				if (index == 1) {
					this.tabindex = 1;
				} else if (index == 2) {
					this.tabindex = 2;
				} else if (index == 3) {
					this.tabindex = 3;
				}
			},
			sharebtn() {
				this.$router.push({
					//url: '/PersonalCenter/pages/InforMainten/MyStaff'
				});
			},
			//新增工作地址
			newAddressbtn(index) {
				this.$router.push({
					path: '/PersonalContent/NewAddress',
					query: {
						pageType: "新增地址信息",
						addTypeId: index
					},
				});
			},
			//修改地址
			editAddress(item) {
				this.$router.push({
					path: '/PersonalContent/NewAddress',
					query: {
						pageType: "修改地址信息",
						fAddressID: item.fAddressID,
						addTypeId: item.fAddressTypeID
					},
				});
			},
			handleSizeChange(val) {
				this.$emit('handleSizeChange', val)
			},
			handleCurrentChange(val) {
				this.$emit('handleCurrentChange', val);
				this.currentPage = val;

			}
		},
		filters: {
			//电话加密
			formatPhone: function(val) {
				return `${val.substring(0,3)}****${val.substring(val.length-3)}`
			}

		},
		watch: {
			tabindex(curVal, oldVal) {
				console.log(this.tabindex);
				this.getProjectList();
			}
		}
	};
</script>
<style lang="scss" scoped>
	.ora-color-theme {
		@include themify($themes) {
			color: #f37b1d;
		}
	}

	.bg-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	.myAddressContent {
		display: flex;
		padding: 0px 14px;
		margin-top: 10px;
		flex-direction: column;
		width: 100%;
		height: calc(100% - 10px);
		background-color: #fff;
		box-sizing: border-box;
	}

	.rightContent {
		height: 100%;
	}

	.margin-l-auto {
		margin-left: auto;
	}

	.margin-t-5 {
		margin-top: 5px;
	}

	.titleBox {
		height: 40px;
		line-height: 37px;
	}

	.street {
		height: 20px;
		word-break: break-all;
		display: -webkit-box; //盒子模型
		-webkit-line-clamp: 1; //设置行数，2行显示
		-webkit-box-orient: vertical; //元素的排列方式，垂直居中
		overflow: hidden; //隐藏溢出内容
		text-overflow: ellipsis; //超出的内容显示省略号

	}

	.tabBox {
		height: 35px;
		line-height: 35px;
		border-bottom: 1px solid #eee;
		padding-bottom: 2px;
	}

	.tabBox span {
		display: inline-block;
		width: 80px;
		height: 35px;
		line-height: 35px;
	}

	.tabBox .navbtn {
		font-size: 14px;
		color: #0173FE;
		font-weight: bold;
		border-bottom: 2px solid #0173FE;
	}

	/* 公用btn */
	.comBtn {
		width: 110px;
		height: 30px;
		line-height: 30px;
		cursor: pointer;
	}

	.tabCont {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 10px;
	}

	.deletrow {
		width: calc(50% - 9px);
		width: -webkit-calc(50% - 9px);
		width: -moz-calc(50% - 9px);
		margin-bottom: 14px;
		border-radius: 10px;
		border: 1px solid #dddddd;
		/* 阴影效果 */
		box-shadow: 2px 2px 6px #d9d9d9;
		-webkit-box-shadow: 2px 2px 6px #d9d9d9;
		-moz-box-shadow: 2px 2px 6px #d9d9d9;
	}

	.deletrow:nth-of-type(2n+1) {
		/* margin-right: 15px; */
	}

	.orderstate {
		border-top: 1px solid #eee;
		height: 35px;
		line-height: 35px;
	}

	.ordername {
		width: 100%;
		height: 19px;
		line-height: 19px;
		font-size: 13px;
		font-weight: bold;
		color: #000000;

	}

	.pronamespan {
		max-width: 245px;
	}

	.ordertext {
		height: 40px;
		font-size: 12px;
		color: #666;
		padding: 0 10px;
		line-height: 18px;
	}

	.orderstate {
		font-size: 12px;
		color: #333;
	}

	.u-icon {
		float: right;
		margin-right: 10px;
	}

	.ordername em {
		width: 30px;
		height: 15px;
		line-height: 15px;
		background: #0173FE;
		border-radius: 2px;
		font-size: 10px;
		color: #ffffff;
		font-style: normal;
		text-align: center;
		font-weight: normal;
		padding: 0 3px;
	}

	.pronametext {
		margin-right: 5px;
	}

	.persontel {
		text-align: right;
		width: 130px;
		height: 17px;
		font-size: 12px;
		color: #666;
		font-weight: normal;
	}

	.defaultorder,
	.modifyorder {
		margin-bottom: 10px;
		align-items: center;
		justify-content: center;
	}

	.orderstate .shareaddress {
		color: #FF9900;
	}

	.editIconBtn,
	.deletIconBtn {
		color: #000;
		float: right;
		margin: 0px 5px;
	}

	.deletIconBtn {
		font-size: 19px;
		margin-top: -2px;
	}

	.editIconBtn {
		font-size: 18px;
	}

	.bgc-pink {
		height: 30px;
		background: rgba(255, 245, 223, 1);
		margin-top: 20px;

		.icon-tishi5 {
			margin: 5px 5px 9px 10px;
			color: #ff9900;
		}

		.attestat_box {
			width: 100%;
			line-height: 30px;
			color: #ff9900;
			text-align: left;
		}
	}
</style>
